<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form inline size="small">
					<el-form-item :label="$t('teacher.tableHead.activityName')">
						<el-input v-model="listQuery.name"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.marketTag')">
						<el-select v-model="listQuery.activity_label_id">
							<el-option v-for="tag in tagsOptions" :key="tag.activity_label_id" :label="tag.label_name"
								:value="tag.activity_label_id" />
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
						<el-button type="text" icon="el-icon-brush" @click="handleClearSearch">{{$t('teacher.actions.clear')}}</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-actions">
				<el-button type="success" size="small" @click="$router.push({ name: 'teacherMarketDetail' })">{{ $t('teacher.actions.addActivity') }}</el-button>
				<el-button type="warning" size="small" @click="$router.push({ name: 'teacherMarketTags' })">{{ $t('teacher.actions.tagManage') }}</el-button>
			</div>
			<div class="t-page-table">
				<el-table :data="pageList">
					<el-table-column :label="$t('teacher.tableHead.activityName')" prop="name"></el-table-column>
					<el-table-column :label="$t('teacher.formItem.marketTag')" prop="label_name"></el-table-column>
					<el-table-column :label="$t('teacher.formItem.shopEnrollTime')">
						<template slot-scope="scope">{{ scope.row.s_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }} ~ {{ scope.row.s_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.formItem.goodsEnrollTime')">
						<template slot-scope="scope">{{ scope.row.g_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }} ~ {{ scope.row.g_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.formItem.preheatTime')">
						<template slot-scope="scope">{{ scope.row.warm_up_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }} ~ {{ scope.row.warm_up_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.formItem.salesTime')">
						<template slot-scope="scope">{{ scope.row.sales_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }} ~ {{ scope.row.sales_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.formItem.activityStatus')">
						<template slot="header" slot-scope="{}">
							<el-select v-model="listQuery.status" size="mini" @change="handleSearch">
								<el-option v-for="item in $t('teacher.option.activityStatus')" :key="item.value" :label="item.label" :value="item.value" />
							</el-select>
						</template>
						<template slot-scope="scope">{{ scope.row.status | typeFilter($t('teacher.filters.activityStatus')) }}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.actions')">
						<template slot-scope="scope">
							<el-button type="primary" size="mini" @click="$router.push({ name: 'teacherMarketDetail', params: { id: scope.row.activity_id } })">{{ $t('teacher.actions.edit') }}</el-button>
							<el-button v-if="scope.row.can_delete === 1" type="danger" size="mini" @click="handleDelete(scope)">{{ $t('teacher.actions.delete') }}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
					@pagination="getList"></pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchMarketList, fetchMarketTags, deleteMarket } from '@/api/teacher'
const defaultQuery = {
	name: '',
	status: '',
	activity_label_id: ''
}
export default {
	components: { Pagination },
	filters: {
		statusFilter(status, option) {
			let statusText = ''
			option.forEach(item => {
				if (item.value === status) {
					statusText = item.label
				}
			})
			return statusText
		}
	},
	data() {
		return {
			tagsOptions: [],
			listQuery: Object.assign({}, defaultQuery),
			pageList: [],
			pageTotal: 0,
			pageCurr: 1,
			pageSize: 10
		}
	},
	created() {
		this.getData()
	},
	methods: {
		async getData() {
			try {
				const tagsData = await fetchMarketTags()
				this.tagsOptions = tagsData.data.list
			} catch (error) {
				console.log(error)
			}
			this.getList()
		},
		getList() {
			const params = {
				...this.listQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchMarketList(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
			})
		},
		handleSearch() {
			this.pageCurr = 1
			this.getList()
		},
		handleClearSearch() {
			this.listQuery = Object.assign({}, defaultQuery)
			this.pageCurr = 1
			this.getList()
		},
		handleDelete({ row }) {
			this.$confirm(this.$t('teacher.confirm.deleteActivity'), this.$t('teacher.confirm.title'), {
				confirmButtonText: this.$t('teacher.actions.confirm1'),
				cancelButtonText: this.$t('teacher.actions.cancel1'),
				type: 'warning'
			}).then(async () => {
				await deleteMarket({ activity_id: row.activity_id })
				this.getList()
				this.$message.success(this.$t('teacher.successMsg.delete'))
			}).catch(() => { })
		}
	}
}
</script>

<style lang="scss" scoped>
.t-page {
	padding: 24px;

	.t-page-screen {
		.el-button--text {
			color: #777;
		}
	}
	.t-page-actions {
		margin-bottom: 15px;
	}
	.t-page-table {
		::v-deep .el-table {
			th.el-table__cell {
				background-color: $--seller-thead-background-1;
			}
		}
	}
}
</style>